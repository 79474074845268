/**
* Template Name: Impact
* Template URL: https://bootstrapmade.com/impact-bootstrap-business-website-template/
* Updated: Apr 4 2024 with Bootstrap v5.3.3
* Author: BootstrapMade.com
* License: https://bootstrapmade.com/license/
*/

/*--------------------------------------------------------------
# Set main reusable colors and fonts using CSS variables
# Learn more about CSS variables at https://developer.mozilla.org/en-US/docs/Web/CSS/Using_CSS_custom_properties
--------------------------------------------------------------*/
/* Fonts */
:root {
  --font-default: "Open Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-primary: "Montserrat", sans-serif;
  --font-secondary: "Poppins", sans-serif;
}

/* Colors */
:root {
  --color-default: #222222;
  /* --color-primary: #008374; */
  /* --color-primary:#9d7cbf; */
  --color-primary:#D94B93;
  --color-secondary:#D94B93;
  /* --color-secondary: #f85a40; */
}

/* Smooth scroll behavior */
:root {
  scroll-behavior: smooth;
}

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
body {
  font-family: var(--font-default);
  color: var(--color-default);
}

a {
  color: #a31d6ddb;
  text-decoration: none;
}

a:hover {
  color: #00b6a1;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-primary);
}

/*--------------------------------------------------------------
# Sections & Section Header
--------------------------------------------------------------*/
section {
  padding: 60px 0;
  overflow: hidden;
  scroll-margin-top: 70px;
  overflow: clip;
}



.sections-bg {
  /* background-color: #f6f6f6; */
}

.section-header {
  text-align: center;
  padding-bottom: 30px;
}
.section-header1 h2{
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
  margin-top: 30px;
  text-align: center;
  padding-bottom: 60px;

}

.section-header1 h2:after {
  content: "";
  position: absolute;
  display: block;
  width: 50px;
  height: 3px;
  /* background: var(--color-primary); */
  background: black;
  left: 0;
  right: 0;
  bottom: 40px;
  margin: auto;

}

.section-lang {
  display: flex;
  justify-content: center;
  align-items: center;
}

.section-lang h3 {
  display: inline-block;
  text-align: center;
  border: 2.5px solid white;
  padding: 7px;
  margin-bottom: 40px;
  background-color: #cb69a2;
  border-radius: 10px;
  font-size: 22px;
  font-weight: 600;
  color: white;
}

.section-lang1 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.section-lang1 h3 {
  display: inline-block;
  text-align: center;
  border: 2.5px solid white;
  padding: 7px;
  margin-bottom: 40px;
  background-color: #cb69a2;
  border-radius: 10px;
  font-size: 22px;
  font-weight: 600;
  color: white;
}






.section-header h2 {
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
}

.section-header h2:after {
  content: "";
  position: absolute;
  display: block;
  width: 50px;
  height: 3px;
  /* background: var(--color-primary); */
  background: black;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;

}

.section-header p {
  margin-bottom: 0;
  color: #6f6f6f;
  font-size: 16px;
  font-family: Nunito,sans-serif;
  text-align: justify;
}



.service-item {
  position: relative;
  padding: 20px;
  text-align: center;
  background-color: #f9f9f9;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.service-item:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.service-item img {
  width: 100px; 

}

.service-item h3 {
  margin-top: 10px;
  margin-bottom: 15px;
  font-size: 24px;
  color: #333;
}

.service-item p {
  font-size: 16px;
  color: #666;
  line-height: 1.5;
}

.col-lg-3,
.col-md-6 {
  position: relative;
  padding: 15px;
}

.icon-skills {
  text-align: center;
  margin: 0 auto;
  /* background-color:white; */
}


.icon-skills img {
  width: 100px;
  /* height: 80px; */
  text-align: center;
  align-items: center;
  justify-content: center;
  /* border-radius: 42px; */
  width: 100px;
}
.icon-star{
  color:#a31d6ddb;
  font-size: 20px;
  list-style-type: none;
  padding-left:0;
  

}
.icon-star p{
  margin-top: 30px;
  font-size: 20px;
  font-weight: bold;
}


.ind-ser {
  max-width: 100%;
  height: auto;
  border-radius: 50%; /* This makes the image circular if it's a square */
  transition: transform 0.3s ease-in-out;
}


.icon-skills1 {
  text-align: center;
  border-radius: 2px;
  
  
  

}

.language{
  text-align: center;
  color: #fff;
  font-weight: bold;
  font-size: 1rem;
  /* margin-top: 50px; */
}

 .ind-ser{
  width: 100px;
  background-color: #fff;
}


.mobile-nav-toggle {
  display: none;
  font-size: 24px;
  cursor: pointer;
  color: #444444;
}

.mobile-nav-toggle {
  display: none;
  font-size: 24px;
  cursor: pointer;
}

.navbar-mobile {
  position: absolute;
  top: 70px; /* Adjust based on your header height */
  left: 0;
  right: 0;
  background: #fff;
  flex-direction: column;
  gap: 0;
  padding: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.navbar-mobile ul {
  flex-direction: column;
  align-items: flex-start;
}

.navbar-mobile li {
  width: 100%;
}

.navbar-mobile a {
  padding: 0.5rem 1rem;
  width: 100%;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .navbar {
    display: none;
  }

  .mobile-nav-toggle {
    display: block;
  }

  .navbar-mobile {
    display: flex;
  }
}




.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.col-lg-4,
.col-md-6 {
  padding-right: 15px;
  padding-left: 15px;
  margin-bottom: 30px;
}

.service-item {
  position: relative;
  padding: 20px;
  text-align: center;
  background-color: #f9f9f9;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.service-item:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.service-item .icon {
  font-size: 2rem;
  color: #007bff;
  margin-bottom: 15px;
  text-align: center;
}

.service-item h3 {
  font-size: 1.3rem;
  margin-top: 10px;
  /* margin-bottom: 15px; */
  color: #333;
  /* text-align: center; */
  align-items: center;
  justify-content: center;
}

.service-item p {
  font-size: 1rem;
  color: #666;
  line-height: 1.5;
  /* text-align: left; */
}



/*--------------------------------------------------------------
# Breadcrumbs
--------------------------------------------------------------*/
.breadcrumbs .page-header {
  padding: 60px 0 0px 0;
  /* min-height: 20vh; */
  position: relative;
  margin-bottom: 0;
    color: #6f6f6f;
    font-size: 20px;
  /* background-color: var(--color-primary); */
  /* background-color: rgb(15, 29, 109) */
}

.breadcrumbs .page-header h2 {
  font-size: 32px;
  font-weight: 600;
  color: black;
  font-family: var(--font-secondary);
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
  margin-top: -40px;
}

.breadcrumbs .page-header h2:after{
  content: "";
  position: absolute;
  display: block;
  width: 50px;
  height: 3px;
  /* background: var(--color-primary); */
  background: black;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.breadcrumbs .page-header p {
  color: #6f6f6f;
  margin-bottom: -40px;
  font-size: 20px;

}

.breadcrumbs nav {
  /* background-color: #f6f6f6; */
  padding: 20px 0;
}

.breadcrumbs nav ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: 600;
  color: var(--color-default);
}

.breadcrumbs nav ol a {
  color: var(--color-primary);
  transition: 0.3s;
}

.breadcrumbs nav ol a:hover {
  text-decoration: underline;
}

.breadcrumbs nav ol li+li {
  padding-left: 10px;
}

.breadcrumbs nav ol li+li::before {
  display: inline-block;
  padding-right: 10px;
  color: var(--color-secondary);
  content: "/";
}

/*--------------------------------------------------------------
# Scroll top button
--------------------------------------------------------------*/
.scroll-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: -15px;
  z-index: 99999;
  background: var(--color-secondary);
  width: 44px;
  height: 44px;
  border-radius: 50px;
  transition: all 0.4s;
}

.scroll-top i {
  font-size: 24px;
  color: #fff;
  line-height: 0;
}

.scroll-top:hover {
  background: rgba(248, 90, 64, 0.8);
  color: #fff;
}

.scroll-top.active {
  visibility: visible;
  opacity: 1;
  bottom: 15px;
}

/*--------------------------------------------------------------
# Preloader
--------------------------------------------------------------*/
#preloader {
  position: fixed;
  inset: 0;
  z-index: 999999;
  overflow: hidden;
  background: #fff;
  transition: all 0.6s ease-out;
}

#preloader:before {
  content: "";
  position: fixed;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  border: 6px solid #fff;
  border-color: var(--color-primary) transparent var(--color-primary) transparent;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: animate-preloader 1.5s linear infinite;
}

@keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Disable aos animation delay on mobile devices
--------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
.topbar {
  /* background: #07255ecc; */
  /* background: #869392; */
  background: #10388f;
  height: 40px;
  font-size: 14px;
  transition: all 0.5s;
  color: #fff;
  padding: 0;
}

.topbar .contact-info i {
  font-style: normal;
  color: #fff;
  line-height: 0;
}

.topbar .contact-info i a,
.topbar .contact-info i span {
  padding-left: 5px;
  color: #fff;
}

@media (max-width: 575px) {

  .topbar .contact-info i a,
  .topbar .contact-info i span {
    font-size: 13px;
  }
}

.topbar .contact-info i a {
  line-height: 0;
  transition: 0.3s;
}

.topbar .contact-info i a:hover {
  color: #fff;
  text-decoration: underline;
}

.topbar .social-links a {
  color: rgba(255, 255, 255, 0.7);
  line-height: 0;
  transition: 0.3s;
  margin-left: 20px;
}

.topbar .social-links a:hover {
  color: #fff;
}

.header {
  transition: all 0.5s;
  z-index: 997;
  height: 90px;
  /* background-color: var(--color-primary); */
}

.header.sticked {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: 70px;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
}

.header .logo img {
  max-height: 80px;
  margin-right: 6px;
}

.header .logo h1 {
  font-size: 30px;
  margin: 0;
  font-weight: 600;
  letter-spacing: 0.8px;
  color: #fff;
  font-family: var(--font-primary);
}

.header .logo h1 span {
  color: #f96f59;
}

.sticked-header-offset {
  margin-top: 70px;
}

/*--------------------------------------------------------------
# Desktop Navigation
--------------------------------------------------------------*/
@media (min-width: 1280px) {
  .navbar {
    padding: 0;
  }

  .navbar ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    align-items: center;
  }

  .navbar li {
    position: relative;
  }

  .navbar>ul>li {
    white-space: nowrap;
    padding: 10px 0 10px 28px;
  }

  .navbar a,
  .navbar a:focus {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 3px;
    font-family: var(--font-secondary);
    font-size: 16px;
    font-weight: 600;
    /* color: rgba(255, 255, 255, 0.6); */
    color: #6f6f6f;
    white-space: nowrap;
    transition: 0.3s;
    position: relative;
  }

  .navbar a i,
  .navbar a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
  }

  .navbar>ul>li>a:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: -6px;
    left: 0;
    /* background-color: var(--color-secondary); */
    background-color: #D94B93;
    visibility: hidden;
    width: 0px;
    transition: all 0.3s ease-in-out 0s;
  }

  .navbar a:hover:before,
  .navbar li:hover>a:before,
  .navbar .active:before {
    visibility: visible;
    width: 100%;
  }

  .navbar a:hover,
  .navbar .active,
  .navbar .active:focus,
  .navbar li:hover>a {
    /* color: #fff; */
    color: #6f6f6f;
  }

  .navbar .dropdown ul {
    display: block;
    position: absolute;
    left: 28px;
    top: calc(100% + 30px);
    margin: 0;
    padding: 10px 0;
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
    transition: 0.3s;
    border-radius: 4px;
  }

  .navbar .dropdown ul li {
    min-width: 200px;
  }

  .navbar .dropdown ul a {
    padding: 10px 20px;
    font-size: 15px;
    text-transform: none;
    font-weight: 600;
    color: #006a5d;
  }

  .navbar .dropdown ul a i {
    font-size: 12px;
  }

  .navbar .dropdown ul a:hover,
  .navbar .dropdown ul .active:hover,
  .navbar .dropdown ul li:hover>a {
    color: var(--color-secondary);
  }

  .navbar .dropdown:hover>ul {
    opacity: 1;
    top: 100%;
    visibility: visible;
  }

  .navbar .dropdown .dropdown ul {
    top: 0;
    left: calc(100% - 30px);
    visibility: hidden;
  }

  .navbar .dropdown .dropdown:hover>ul {
    opacity: 1;
    top: 0;
    left: 100%;
    visibility: visible;
  }
}

@media (min-width: 1280px) and (max-width: 1600px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }

  .navbar .dropdown .dropdown:hover>ul {
    left: -100%;
  }
}

@media (min-width: 1280px) {

  .mobile-nav-show,
  .mobile-nav-hide {
    display: none;
    
  }
}

/*--------------------------------------------------------------
# Mobile Navigation
--------------------------------------------------------------*/
.header .mobile-nav-toggle {
  display: none;
  font-size: 24px;
  cursor: pointer;
}

.header .navbar-mobile {
  display: none;
}

/* Mobile Styles */
@media (max-width: 992px) {
  .header .navbar {
    display: none;
  }

  .header .navbar-mobile {
    display: flex;
    flex-direction: column;
    align-items: initial;
    position: absolute;
    top: 77px; /* Adjust based on your header height */
    /* left: 45px; */
    /* width: 0; */
    /* background: #fff; */
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px 0;
    z-index:999;
  }

  .header .navbar-mobile ul {
    flex-direction: column;
  }

  .header .navbar-mobile ul li {
    margin: 10px 0;
  }

  .header .mobile-nav-toggle {
    display: block;
  }
}
/*--------------------------------------------------------------
# About Us Section
--------------------------------------------------------------*/
.about h3 {
  font-weight: 700;
  font-size: 28px;
  margin-bottom: 20px;
  font-family: var(--font-secondary);
  color: #ad4283;
}

.about h5 {
  font-weight: 700;
  font-size: 28px;
  margin-bottom: 0px;
  font-family: var(--font-secondary);
  color: #ad4283;
}


.about .call-us {
  left: 10%;
  right: 10%;
  bottom: 0;
  background-color: #fff;
  box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.08);
  padding: 20px;
  text-align: center;
}

.about .call-us h4 {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 5px;
}

.about .call-us p {
  font-size: 28px;
  font-weight: 700;
  color: var(--color-primary);
}

.about .content ul {
  list-style: none;
  padding: 0;
}

.about .content ul li {
  padding: 0 0 10px 30px;
  position: relative;
}

.about .content ul i {
  position: absolute;
  font-size: 20px;
  left: 0;
  top: -3px;
  /* color: var(--color-primary); */
  color: #D94B93;
}

.about .content p:last-child {
  margin-bottom: 0;
}

.about .play-btn {
  width: 94px;
  height: 94px;
  background: radial-gradient(var(--color-primary) 50%, rgba(0, 131, 116, 0.4) 52%);
  border-radius: 50%;
  display: block;
  position: absolute;
  left: calc(50% - 47px);
  top: calc(50% - 47px);
  overflow: hidden;
}

.about .play-btn:before {
  content: "";
  position: absolute;
  width: 120px;
  height: 120px;
  animation-delay: 0s;
  animation: pulsate-btn 2s;
  animation-direction: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: steps;
  opacity: 1;
  border-radius: 50%;
  border: 5px solid rgba(0, 131, 116, 0.7);
  top: -15%;
  left: -15%;
  background: rgba(198, 16, 0, 0);
}

.about .play-btn:after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-40%) translateY(-50%);
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid #fff;
  z-index: 100;
  transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.about .play-btn:hover:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-40%) translateY(-50%);
  width: 0;
  height: 0;
  border: none;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid #fff;
  z-index: 200;
  animation: none;
  border-radius: 0;
}

.about .play-btn:hover:after {
  border-left: 15px solid var(--color-primary);
  transform: scale(20);
}

@keyframes pulsate-btn {
  0% {
    transform: scale(0.6, 0.6);
    opacity: 1;
  }

  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}

/* Form Container */

.col-lg-12 {
  width: 100%;
  max-width: 800px;
  margin: auto;
}

/* Form Styling */
.php-email-form {
  background: #fff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.php-email-form .form-group {
  margin-bottom: 20px;
}

.php-email-form .form-control {
  border-radius: 5px;
  border: 1px solid #ddd;
  padding: 15px;
  font-size: 16px;
  background-color: #f9f9f9;
  transition: background-color 0.3s ease;
}

.php-email-form .form-control:focus {
  background-color: #fff;
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

/* Placeholder Styling */
.php-email-form .form-control::placeholder {
  color: #999;
}

/* Select Styling */
.php-email-form select.form-control {
  appearance: none;
  background: url('data:image/svg+xml;utf8,<svg fill="none" height="24" stroke="%23999" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><polyline points="6 9 12 15 18 9"/></svg>') no-repeat right 10px center;
  padding-right: 40px;
  
}

/* Textarea Styling */
.php-email-form textarea.form-control {
  resize: none;
}

/* Loading and Message Styling */
.my-3 {
  text-align: center;
}

.loading,
.error-message,
.sent-message {
  display: none;
  padding: 10px;
  margin-top: 10px;
  border-radius: 5px;
}

.loading {
  background: #ffc107;
  color: #fff;
}

.error-message {
  background: #dc3545;
  color: #fff;
}

.sent-message {
  background: #28a745;
  color: #fff;
}

/* Button Styling */
.php-email-form button[type="submit"] {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 15px 30px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.php-email-form button[type="submit"]:hover {
  background-color: #0056b3;
}

/* Responsive Design */
@media (max-width: 768px) {
  .php-email-form .form-group {
    margin-bottom: 15px;
  }

  .php-email-form button[type="submit"] {
    width: 100%;
    padding: 15px;
  }
}


/*--------------------------------------------------------------
# Clients Section
--------------------------------------------------------------*/
.clients {
  padding: 40px 0;
}

.clients .swiper {
  padding: 10px 0;
}

.swiper-slide  {
  /* background: #D94B93; */
}

.clients .swiper-slide img {
  transition: 0.3s;
}

.clients .swiper-slide img:hover {
  transform: scale(1.1);
  background: #D94B93;
}
.testimonial-item:hover {
  background: #D94B93;
}

.testimonial-item:hover .testimonial-details h3 {
  color: #ffff;
}
.testimonial-item:hover .testimonial-details h4 {
  color: #ffff;
}

.testimonial-item:hover .testimonial-details i {
  color: #ffff;
}

.testimonial-item:hover .testimonial-details .testimonial-text {
  color: #ffff;
}

.clients .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.clients .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #fff;
  opacity: 1;
  background-color: #ddd;
}

.clients .swiper-pagination .swiper-pagination-bullet-active {
  background-color: var(--color-primary);
}

/*--------------------------------------------------------------
# Stats Counter Section
--------------------------------------------------------------*/
.stats-counter {
  padding: 40px 0;
}

.image{
  max-width: 80%;
  height: 330px;
  margin-top: 40px ;
  border-radius: 25px;
}

.stats-counter .stats-item {
  padding: 20px 0;
  border-bottom: 1px solid #e4e4e4;
}

.stats-counter .stats-item .purecounter {
  min-width: 90px;
  padding-right: 15px;
}

.stats-counter .stats-item i {
  font-size: 44px;
  line-height: 0;
  margin-right: 15px;
}

.stats-counter .stats-item span {
  font-size: 40px;
  display: block;
  font-weight: 700;
  /* color: var(--color-primary); */
  color: #D94B93;
  line-height: 40px;
}

.stats-counter .stats-item p {
  margin: 0;
  font-family: var(--font-primary);
  font-size: 14px;
}

/*--------------------------------------------------------------
# Call To Action Section
--------------------------------------------------------------*/
.call-to-action .container {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../img/cta-bg.jpg") center center;
  background-size: cover;
  padding: 100px 60px;
  border-radius: 15px;
  overflow: hidden;
}

.call-to-action h3 {
  color: #fff;
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 20px;
}

.call-to-action p {
  color: #fff;
  margin-bottom: 20px;
}

.call-to-action .play-btn {
  width: 94px;
  height: 94px;
  margin-bottom: 20px;
  background: radial-gradient(var(--color-primary) 50%, rgba(0, 131, 116, 0.4) 52%);
  border-radius: 50%;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.call-to-action .play-btn:before {
  content: "";
  position: absolute;
  width: 120px;
  height: 120px;
  animation-delay: 0s;
  animation: pulsate-btn 2s;
  animation-direction: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: steps;
  opacity: 1;
  border-radius: 50%;
  border: 5px solid rgba(0, 131, 116, 0.7);
  top: -15%;
  left: -15%;
  background: rgba(198, 16, 0, 0);
}

.call-to-action .play-btn:after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-40%) translateY(-50%);
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid #fff;
  z-index: 100;
  transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.call-to-action .play-btn:hover:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-40%) translateY(-50%);
  width: 0;
  height: 0;
  border: none;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid #fff;
  z-index: 200;
  animation: none;
  border-radius: 0;
}

.call-to-action .play-btn:hover:after {
  border-left: 15px solid var(--color-primary);
  transform: scale(20);
}

.call-to-action .cta-btn {
  font-family: var(--font-primary);
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 12px 48px;
  border-radius: 50px;
  transition: 0.5s;
  margin: 10px;
  border: 2px solid #fff;
  color: #fff;
}

.call-to-action .cta-btn:hover {
  background: var(--color-primary);
  border: 2px solid var(--color-primary);
}

@keyframes pulsate-btn {
  0% {
    transform: scale(0.6, 0.6);
    opacity: 1;
  }

  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}

/*--------------------------------------------------------------
# Our Services Section
--------------------------------------------------------------*/
.services {
  padding: 60px 0;
}

.services .service-item {
  padding: 40px;
  background: #D94B93;
  height: 100%;
  border-radius: 10px;
}

.services .service-item1 {
  padding: 40px;
  background: #D94B93;
  height: 100%;
  border-radius: 10px;
}

.services .service-item .icon {
  /* width: 48px; */
  height: 48px;
  position: relative;
  display: flex;
  justify-content:center;
  align-items:center;
}

.services .service-item .icon i {
  color:white;
  font-size: 56px;
  transition: ease-in-out 0.3s;
  z-index: 2;
  position: relative;
  /* margin-left: 123px; */
  margin-bottom: 30px;
  
}
.hero {
  /* display: flex; */
  justify-content: space-between;
  align-items: center;
  display: contents;
}

.hero-text {
  width: 50%;
}

.hero-image {
  width: 50%;
}

.hero-image img {
  width: 100%;
}

/* .services .service-item .icon:before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  
  border-radius: 50px;
  z-index: 1;
  top: 10px;
  right: -20px;
  transition: 0.3s;
} */

.services .service-item h3 {
  /* color: var(--color-default); */
  color:#ffff;
  font-weight: 700;
  
  padding-bottom: 15px;
  font-size: 24px;
  position: relative; 
   display: inline-block ;
  /* border-bottom: 4px solid #eeeeee; 
  transition: 0.3s; */
  
  
  
  
}

.services .service-item p {
  line-height: 24px;
  font-size: 20px;
  margin-top: 0;
  color: #fff;
  font-family: Nunito,sans-serif
}

.services .service-item .readmore {
  margin-top: 15px;
  display: inline-block;
  color: var(--color-primary);
}

.services .service-item:hover .icon:before {
  /* background: var(--color-primary); */
  background: #a31d6ddb;
}

.services .service-item:hover h3 {
  /* border-color: var(--color-primary); */
  border-color: #a31d6ddb;
}

/*--------------------------------------------------------------
# Testimonials Section
--------------------------------------------------------------*/
.testimonials .testimonial-wrap {
  padding-left: 10px;
}

.testimonials .testimonial-item {
  box-sizing: content-box;
  padding: 30px;
  margin: 30px 10px;
  box-shadow: 0px 0 15px rgba(0, 0, 0, 0.1);
  position: relative;
  /* background: #fff; */
  border-radius: 10px;
}

.testimonials .testimonial-item .testimonial-img {
  width: 90px;
  border-radius: 50px;
  margin-right: 15px;
}

.testimonials .testimonial-item h3 {
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0 5px 0;
  color: #000;
}

.testimonials .testimonial-item h4 {
  font-size: 14px;
  color: #999;
  margin: 0;
}

.testimonials .testimonial-item .stars {
  margin: 10px 0;
}

.testimonials .testimonial-item .stars i {
  color: #ffc107;
  margin: 0 1px;
}

.testimonials .testimonial-item .quote-icon-left,
.testimonials .testimonial-item .quote-icon-right {
  color: #D94B93;
  font-size: 26px;
  line-height: 0;
}

.testimonials .testimonial-item .quote-icon-left {
  display: inline-block;
  left: -5px;
  position: relative;
}

.testimonials .testimonial-item .quote-icon-right {
  display: inline-block;
  right: -5px;
  position: relative;
  top: 10px;
  transform: scale(-1, -1);
}

.testimonials .testimonial-item p {
  font-style: italic;
  margin: 15px auto 15px auto;
}

.testimonials .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.testimonials .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: rgba(0, 0, 0, 0.2);
  opacity: 1;
}

.testimonials .swiper-pagination .swiper-pagination-bullet-active {
  background-color: var(--color-primary);
}

@media (max-width: 1388px) {
  .testimonials .testimonial-wrap {
    padding-left: 5px;
  }

  .testimonials .testimonial-item {
    padding: 20px;
    margin: 20px 5px;
  }

  .testimonials .testimonial-item .testimonial-img {
    margin-right: 10px;
  }

  .testimonials .testimonial-item h3 {
    font-size: 16px;
  }

  .testimonials .testimonial-item h4 {
    font-size: 12px;
  }

  .testimonials .testimonial-item p {
    font-size: 14px;
  }
}

@media (max-width: 1024px) {
  .testimonials .testimonial-wrap {
    padding-left: 0;
  }

  .testimonials .testimonial-item {
    padding: 20px;
    margin: 15px 5px;
  }

  .testimonials .testimonial-item .testimonial-img {
    margin-right: 10px;
  }

  .testimonials .testimonial-item h3 {
    font-size: 16px;
  }

  .testimonials .testimonial-item h4 {
    font-size: 12px;
  }

  .testimonials .testimonial-item p {
    font-size: 14px;
  }
}

@media (max-width: 600px) {
  .testimonials .testimonial-wrap {
    padding-left: 0;
  }

  .testimonials .testimonial-item {
    padding: 15px;
    margin: 10px;
  }

  .testimonials .testimonial-item .testimonial-img {
    width: 70px;
    margin-right: 10px;
  }

  .testimonials .testimonial-item h3 {
    font-size: 14px;
  }

  .testimonials .testimonial-item h4 {
    font-size: 10px;
  }

  .testimonials .testimonial-item p {
    font-size: 12px;
  }
}

/*--------------------------------------------------------------
# Portfolio Section
--------------------------------------------------------------*/
.portfolio .portfolio-flters {
  padding: 0 0 20px 0;
  margin: 0 auto;
  list-style: none;
  text-align: center;
}

.portfolio .portfolio-flters li {
  cursor: pointer;
  display: inline-block;
  padding: 0;
  font-size: 18px;
  font-weight: 500;
  margin: 0 10px;
  line-height: 1;
  transition: all 0.3s ease-in-out;
}

.portfolio .portfolio-flters li:hover,
.portfolio .portfolio-flters li.filter-active {
  /* color: var(--color-primary); */
  color: black;
}

.portfolio .portfolio-flters li:first-child {
  margin-left: 0;
}

.portfolio .portfolio-flters li:last-child {
  margin-right: 0;
}

@media (max-width: 575px) {
  .portfolio .portfolio-flters li {
    font-size: 14px;
    margin: 0 5px;
  }
}

.portfolio .portfolio-wrap {
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  height: 100%;
  overflow: hidden;
}

.portfolio .portfolio-wrap img {
  transition: 0.3s;
  position: relative;
  z-index: 1;
}

.portfolio .portfolio-wrap .portfolio-info {
  padding: 28px ;
  background-color: #D94B93;
  position: relative;
  border-top: 1px solid #f3f3f3;
  z-index: 2;
  height: 265px;
  align-items: center;
 
}

.portfolio .portfolio-wrap .portfolio-info h4 {
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  color: #fff;
  
}

.portfolio .portfolio-wrap .portfolio-info h4 a {
  /* color: var(--color-default); */
  color:#fff;
 
  justify-content: center;
  justify-content: center;
    align-items: center;
    display: flex;
  
  font-family: Nunito, sans-serif;
}


.portfolio .portfolio-wrap .portfolio-info p {
  color: #fff;
  font-size: 14px;
  margin-bottom: 0;
  /* padding-right: 50px; */
  background-color: #D94B93;
  text-align: center;
  font-family: Nunito, sans-serif;
}

.portfolio .portfolio-wrap:hover img {
  transform: scale(1.1);
}

/*--------------------------------------------------------------
# Portfolio Details Section
--------------------------------------------------------------*/
.portfolio-details .portfolio-details-slider img {
  width: 100%;
}

.portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: rgba(255, 255, 255, 0.7);
  opacity: 1;
}

.portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet-active {
  background-color: var(--color-primary);
}

.portfolio-details .swiper-button-prev,
.portfolio-details .swiper-button-next {
  width: 48px;
  height: 48px;
}

.portfolio-details .swiper-button-prev:after,
.portfolio-details .swiper-button-next:after {
  color: rgba(255, 255, 255, 0.8);
  background-color: rgba(0, 0, 0, 0.2);
  font-size: 24px;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
}

.portfolio-details .swiper-button-prev:hover:after,
.portfolio-details .swiper-button-next:hover:after {
  background-color: rgba(0, 0, 0, 0.6);
}

@media (max-width: 575px) {

  .portfolio-details .swiper-button-prev,
  .portfolio-details .swiper-button-next {
    display: none;
  }
}

.portfolio-details .portfolio-info h3 {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
}

.portfolio-details .portfolio-info h3:after {
  content: "";
  position: absolute;
  display: block;
  width: 50px;
  height: 3px;
  background: var(--color-primary);
  left: 0;
  bottom: 0;
}

.portfolio-details .portfolio-info ul {
  list-style: none;
  padding: 0;
  font-size: 15px;
}

.portfolio-details .portfolio-info ul li {
  display: flex;
  flex-direction: column;
  padding-bottom: 15px;
  font-size: 16px;
}

.portfolio-details .portfolio-info ul strong {
  text-transform: uppercase;
  font-weight: 400;
  color: #9c9c9c;
  font-size: 12px;
}

.portfolio-details .portfolio-info .btn-visit {
  padding: 8px 40px;
  background: var(--color-primary);
  color: #fff;
  border-radius: 50px;
  transition: 0.3s;
}

.portfolio-details .portfolio-info .btn-visit:hover {
  background: #009d8b;
}

.portfolio-details .portfolio-description h2 {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 20px;
}

.portfolio-details .portfolio-description p {
  padding: 0;
}

.portfolio-details .portfolio-description .testimonial-item {
  padding: 30px 30px 0 30px;
  position: relative;
  background: white;
  height: 100%;
  margin-bottom: 50px;
}

.portfolio-details .portfolio-description .testimonial-item .testimonial-img {
  width: 90px;
  border-radius: 50px;
  border: 6px solid #fff;
  float: left;
  margin: 0 10px 0 0;
}

.portfolio-details .portfolio-description .testimonial-item h3 {
  font-size: 18px;
  font-weight: bold;
  margin: 15px 0 5px 0;
  padding-top: 20px;
}

.portfolio-details .portfolio-description .testimonial-item h4 {
  font-size: 14px;
  color: #6c757d;
  margin: 0;
}

.portfolio-details .portfolio-description .testimonial-item .quote-icon-left,
.portfolio-details .portfolio-description .testimonial-item .quote-icon-right {
  color: #009d8b;
  font-size: 26px;
  line-height: 0;
}

.portfolio-details .portfolio-description .testimonial-item .quote-icon-left {
  display: inline-block;
  left: -5px;
  position: relative;
}

.portfolio-details .portfolio-description .testimonial-item .quote-icon-right {
  display: inline-block;
  right: -5px;
  position: relative;
  top: 10px;
  transform: scale(-1, -1);
}

.portfolio-details .portfolio-description .testimonial-item p {
  font-style: italic;
  margin: 0 0 15px 0 0 0;
  padding: 0;
}

/*--------------------------------------------------------------
# Our Team Section
--------------------------------------------------------------*/
.team .member {
  text-align: center;
  background: #fff;
  border-radius: 10px;
  padding: 15px;
  overflow: hidden;
  box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.1);
}

.team .member img {
  border-radius: 10px;
  overflow: hidden;
}

.team .member .member-content {
  padding: 0 20px 30px 20px;
}

.team .member h4 {
  font-weight: 700;
  margin-top: 16px;
  margin-bottom: 2px;
  font-size: 20px;
}

.team .member span {
  font-style: italic;
  display: block;
  font-size: 14px;
  color: #6c757d;
}

.team .member p {
  padding-top: 10px;
  font-size: 14px;
  font-style: italic;
  color: #6c757d;
}

.team .member .social {
  margin-top: 15px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.team .member .social a {
  color: #a2a2a2;
  transition: 0.3s;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #bbbbbb;
}

.team .member .social a:hover {
  color: var(--color-primary);
  border-color: var(--color-primary);
}

.team .member .social i {
  font-size: 18px;
  margin: 0 2px;
}

/*--------------------------------------------------------------
# Pricing Section
--------------------------------------------------------------*/
.pricing .pricing-item {
  padding: 60px 40px;
  box-shadow: 0 3px 20px -2px rgba(108, 117, 125, 0.15);
  background: #fff;
  height: 100%;
  position: relative;
  border-radius: 15px;
}

.pricing h3 {
  font-weight: 600;
  margin-bottom: 15px;
  font-size: 20px;
  color: #555555;
  text-align: center;
}

.pricing .icon {
  margin: 30px auto 30px auto;
  width: 70px;
  height: 70px;
  background: var(--color-primary);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
  transform-style: preserve-3d;
}

.pricing .icon i {
  color: #fff;
  font-size: 28px;
  transition: ease-in-out 0.3s;
  line-height: 0;
}

.pricing .icon::before {
  position: absolute;
  content: "";
  height: 86px;
  width: 86px;
  border-radius: 50%;
  background: #87c1bb;
  transition: all 0.3s ease-out 0s;
  transform: translateZ(-1px);
}

.pricing .icon::after {
  position: absolute;
  content: "";
  height: 102px;
  width: 102px;
  border-radius: 50%;
  background: #d2eeeb;
  transition: all 0.3s ease-out 0s;
  transform: translateZ(-2px);
}

.pricing h4 {
  font-size: 48px;
  color: var(--color-secondary);
  font-weight: 700;
  font-family: var(--font-secondary);
  margin-bottom: 0;
  text-align: center;
}

.pricing h4 sup {
  font-size: 28px;
}

.pricing h4 span {
  color: rgba(108, 117, 125, 0.8);
  font-size: 18px;
  font-weight: 400;
}

.pricing ul {
  padding: 20px 0;
  list-style: none;
  color: #6c757d;
  text-align: left;
  line-height: 20px;
}

.pricing ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}

.pricing ul i {
  color: #059652;
  font-size: 24px;
  padding-right: 3px;
}

.pricing ul .na {
  color: rgba(108, 117, 125, 0.5);
}

.pricing ul .na i {
  color: rgba(108, 117, 125, 0.5);
}

.pricing ul .na span {
  text-decoration: line-through;
}

.pricing .buy-btn {
  display: inline-block;
  padding: 10px 40px;
  border-radius: 50px;
  color: var(--color-primary);
  border: 1px solid rgba(0, 131, 116, 0.2);
  transition: none;
  font-size: 16px;
  font-weight: 600;
  font-family: var(--font-primary);
  transition: 0.3s;
}

.pricing .buy-btn:hover {
  background: var(--color-primary);
  color: #fff;
}

.pricing .featured {
  z-index: 10;
  border: 3px solid var(--color-primary);
}

@media (min-width: 992px) {
  .pricing .featured {
    transform: scale(1.15);
  }
}

/*--------------------------------------------------------------
# Frequently Asked Questions Section
--------------------------------------------------------------*/
.faq .content h3 {
  font-weight: 400;
  font-size: 34px;
}

.faq .content h4 {
  font-size: 20px;
  font-weight: 700;
  margin-top: 5px;
}

.faq .content p {
  font-size: 15px;
  color: #6c757d;
}

.faq .accordion-item {
  border: 0;
  margin-bottom: 20px;
  box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
}

.faq .accordion-item:last-child {
  margin-bottom: 0;
}

.faq .accordion-collapse {
  border: 0;
}

.faq .accordion-button {
  padding: 20px 50px 20px 20px;
  font-weight: 600;
  border: 0;
  font-size: 18px;
  line-height: 24px;
  color: var(--color-default);
  text-align: left;
  background: #fff;
  box-shadow: none;
  border-radius: 10px;
}

.faq .accordion-button .num {
  padding-right: 10px;
  font-size: 20px;
  line-height: 0;
  color: var(--color-primary);
}

.faq .accordion-button:not(.collapsed) {
  color: var(--color-primary);
  border-bottom: 0;
  box-shadow: none;
}

.faq .accordion-button:after {
  position: absolute;
  right: 20px;
  top: 20px;
}

.faq .accordion-body {
  padding: 0 40px 30px 45px;
  border: 0;
  border-radius: 10px;
  background: #fff;
  box-shadow: none;
}

/*--------------------------------------------------------------
# Recent Blog Posts Section
--------------------------------------------------------------*/
.recent-posts article {
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  padding: 30px;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
}

.recent-posts .post-img {
  max-height: 240px;
  margin: -30px -30px 15px -30px;
  overflow: hidden;
}

.recent-posts .post-category {
  font-size: 16px;
  color: #6f6f6f;
  margin-bottom: 10px;
}

.recent-posts .title {
  font-size: 22px;
  font-weight: 700;
  padding: 0;
  margin: 0 0 20px 0;
}

.recent-posts .title a {
  color: var(--color-default);
  transition: 0.3s;
}

.recent-posts .title a:hover {
  color: var(--color-primary);
}

.recent-posts .post-author-img {
  width: 50px;
  border-radius: 50%;
  margin-right: 15px;
}

.recent-posts .post-author {
  font-weight: 600;
  margin-bottom: 5px;
}

.recent-posts .post-date {
  font-size: 14px;
  color: #3c3c3c;
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Contact Section
--------------------------------------------------------------*/
.contact .info-container {
  /* background-color: var(--color-primary); */
  background-color: #a31d6ddb;
  height: 100%;
  padding: 20px;
  border-radius: 10px 0 0 10px;
  box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.1);
}

.contact .info-item {
  width: 100%;
  /* background-color: #5c6192; */
  background-color: #703358;
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 10px;
  color: #fff;
  /* color: black */
}

.contact .info-item:last-child {
  margin-bottom: 0;
}

.contact .info-item i {
  font-size: 20px;
  color: #fff;
  /* color: black; */
  float: left;
  width: 44px;
  height: 44px;
  background-color: rgba(255, 255, 255, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
  margin-right: 15px;
}

.contact .info-item h4 {
  padding: 0;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 5px;
  color:white;
  font-family:Nunito, sans-serif;
}

.contact .info-item p {
  padding: 0;
  margin-bottom: 0;
  font-size: 14px;
}


.contact .info-item:hover i {
  background: #fff;
  /* background: black; */
  color: var(--color-primary);
}

.contact .php-email-form {
  width: 50%;
  height: 100%;
  background: #a31d6ddb;

  box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.1);
  padding: 30px;
  border-radius: 10px 10px 10px 10px;
  margin-top: -300px;
  margin-left: 350px;
  
}

.contact .php-email-form .form-group {
  padding-bottom: 8px;
  
}

.contact .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #df1529;
  text-align: left;
  padding: 15px;
  font-weight: 600;

}

.contact .php-email-form .error-message br+br {
  margin-top: 25px;
}

.contact .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #059652;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}

.contact .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #059652;
  border-top-color: #fff;
  animation: animate-loading 1s linear infinite;
}

.contact .php-email-form input,
.contact .php-email-form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
}

.contact .php-email-form input[type=text],
.contact .php-email-form input[type=email],
.contact .php-email-form textarea {
  padding: 12px 15px;
  font-weight: bold;
}

.contact .php-email-form input[type=text]:focus,
.contact .php-email-form input[type=email]:focus,
.contact .php-email-form textarea:focus {
  border-color: var(--color-primary);
}

.contact .php-email-form textarea {
  padding: 10px 12px;
}

.contact .php-email-form button[type=submit] {
  background: #fff;
  border: 0;
  padding: 14px 45px;
  color: black;
  transition: 0.4s;
  border-radius: 50px;
  font-weight: bold;
}

.contact .php-email-form button[type=submit]:hover {
  background: rgba(0, 131, 116, 0.8);
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


@media (max-width: 768px) {
  .contact {
    padding: 10px;
  }

  .contact .info-container,
  .contact .php-email-form {
    width: 100%;
    padding: 15px;
    margin-top: 20px;
    margin-left: 0;
  }

  .contact .php-email-form {
    margin-top: -350px;
  }

  .contact .php-email-form input[type=text],
  .contact .php-email-form input[type=email],
  .contact .php-email-form textarea {
    font-size: 12px;
    padding: 10px;
  }

  .contact .php-email-form button[type=submit] {
    padding: 12px 30px;
    font-size: 14px;
  }

  .contact .info-item h4 {
    font-size: 18px;
  }

  .contact .info-item p {
    font-size: 12px;
  }
}

@media (max-width: 480px) {
  .contact .info-item i {
    width: 36px;
    height: 36px;
    font-size: 18px;
    margin-right: 10px;
  }

  .contact .php-email-form input[type=text],
  .contact .php-email-form input[type=email],
  .contact .php-email-form textarea {
    font-size: 12px;
    padding: 8px;
  }

  .contact .php-email-form button[type=submit] {
    padding: 10px 20px;
    font-size: 12px;
  }
}
/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
.hero {
  width: 100%;
  position: relative;
  /* background: #42bcd7; */
  background: white;
  padding: 60px 0 0 0;
}

@media (min-width: 1365px) {
  .hero {
    background-attachment: fixed;
  }
}

.hero h2 {
  font-size: 38px;
  font-weight: 700;
  margin-bottom: 20px;
  /* color: #fff; */
  color: black;
  transform: translateY(30px);

  transition: color 0.3s ease, transform 0.3s ease;
  transition: opacity 1s ease-in-out, transform 1s ease-in-out;
  font-family: Nunito, sans-serif;;

}
.hero h2:hover {
  /* color: #007BFF; */
  color: black;
  transform: scale(1.1); /* Slightly scale up on hover */
}
.hero h2:nth-child(1) {
  animation: wordAnimation 9s infinite;
}
.hero h2:nth-child(2) {
  animation: wordAnimation 9s 3s infinite;
}

.hero h2:nth-child(3) {
  animation: wordAnimation 9s 6s infinite;
}
@keyframes wordAnimation {
  0%, 33.33%, 100% {
    opacity: 1;
    transform: translateY(30px);
  }
  16.66%, 49.99% {
    opacity: 1;
    transform: translateY(0);
  }
}

.hero h4 {
  font-weight: 600;
}



.hero p {
  color:#6f6f6f;
  font-size: 16px;
  margin-bottom: 0;
  font-family: Nunito, sans-serif;
}

.hero .btn-get-started {
  font-family: var(--font-primary);
  font-weight: 500;
  font-size: 15px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 14px 40px;
  border-radius: 50px;
  transition: 0.3s;
  color: #fff;
  /* color: black; */
  /* background: rgba(255, 255, 255, 0.1); */
  /* background: #a31d6ddb; */
  background-image: linear-gradient(#9f005d, #D94B93);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.08);
  border: 2px solid rgba(255, 255, 255, 0.1);
}

.hero .btn-get-started:hover {
  /* border-color: rgba(37, 5, 101, 0.358); */
  border-color: #cb69a2;
  /* background: rgba(48, 19, 98, 0.358); */
  background: #cb69a2;
}

.hero .btn-watch-video {
  font-size: 16px;
  transition: 0.5s;
  margin-left: 25px;
  color: #fff;
  font-weight: 600;
}

.hero .btn-watch-video i {
  border-color: rgba(37, 5, 101, 0.358);
  background: rgba(48, 19, 98, 0.358);
  font-size: 32px;
  transition: 0.3s;
  line-height: 0;
  margin-right: 8px;
}

.hero .btn-watch-video:hover i {
  color: #fff;
}
/* .text-center1 p {
  
  margin-bottom: 0 ;
  color:#6f6f6f;
  font-size:20px;
  font-family: Nunito, sans-serif;
} */

h1 span {
  color: black;/* Change this color to your preferred highlight color */
  font-weight: bold;
  text-transform: uppercase;
  font-size: 32px;
}

@media (max-width: 640px) {
  .hero h2 {
    font-size: 36px;
  }

  .hero .btn-get-started,
  .hero .btn-watch-video {
    font-size: 14px;
  }
}

.hero .icon-boxes {
  padding-bottom: 60px;
}

@media (min-width: 1200px) {
  .hero .icon-boxes:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: calc(50% + 20px);
    /* background-color: #fff; */
    background-color: black;
  }
}

.hero .icon-box {
  padding: 60px 30px;
  position: relative;
  overflow: hidden;
  /* background: #615398; */
  /* background: #D94B93; */
  box-shadow: 0 0 29px 0 rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease-in-out;
  border-radius: 8px;
  z-index: 1;
  height: 100%;
  width: 100%;
  text-align: center;
  /* background-image: linear-gradient(#9f005d, #D94B93); */
  
}

.gradient-text-color {
  background-image: linear-gradient(#9f005d, #D94B93);
  color: transparent !important;
  background-clip: text;
}

.top-box-border {
  box-shadow: 0 0 29px 0 rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease-in-out;
  border-radius: 8px;
}

.hero .icon-box .title {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 24px;
}

.hero .icon-box .title a {
  /* color: #fff; */
  color: #D94B93;
  transition: 0.3s;
}

.hero .icon-box .icon {
  
  transition: all 0.3s ease-in-out;
  font-size: 48px;
  line-height: 1;
  color: #D94B93;
  height: 48px;
  position: relative;
  display: flex;
  justify-content:center;
  align-items:center;
  margin-bottom:10px;
}

.hero .icon-box:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  /* color: #D91966; */
  background-image: linear-gradient(#9f005d, #D94B93);
}

.hero .icon-box:hover .title a,
.hero .icon-box:hover .icon {
  color: #fff;
  /* color: black; */
}

.hero .icon-box:hover .title a,
.hero .icon-box:hover .text-light-1 {
  color: #fff;
  /* color: black; */
}

/*--------------------------------------------------------------
# Blog
--------------------------------------------------------------*/
.blog .blog-pagination {
  margin-top: 30px;
  color: #555555;
}

.blog .blog-pagination ul {
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
}

.blog .blog-pagination li {
  margin: 0 5px;
  transition: 0.3s;
  border-radius: 10px;
}

.blog .blog-pagination li a {
  color: var(--color-default);
  padding: 7px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.blog .blog-pagination li.active,
.blog .blog-pagination li:hover {
  background: var(--color-primary);
  color: #fff;
}

.blog .blog-pagination li.active a,
.blog .blog-pagination li:hover a {
  color: var(--color-white);
}

/* --------------------------------------------------------
#Mthodlogy
--------------------------------------------------------- */

.image-container {
  text-align: center;
  margin-top: 20px;
}

.image-container img {
  max-width: 100%;
  height: auto;
}



/*--------------------------------------------------------------
# Blog Posts List
--------------------------------------------------------------*/
.blog .posts-list article {
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  padding: 30px;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
}

.blog .posts-list .post-img {
  max-height: 240px;
  margin: -30px -30px 15px -30px;
  overflow: hidden;
}

.blog .posts-list .post-category {
  font-size: 16px;
  color: #555555;
  margin-bottom: 10px;
}

.blog .posts-list .title {
  font-size: 22px;
  font-weight: 700;
  padding: 0;
  margin: 0 0 20px 0;
}

.blog .posts-list .title a {
  color: var(--color-default);
  transition: 0.3s;
}

.blog .posts-list .title a:hover {
  color: var(--color-primary);
}

.blog .posts-list .post-author-img {
  width: 50px;
  border-radius: 50%;
  margin-right: 15px;
}

.blog .posts-list .post-author-list {
  font-weight: 600;
  margin-bottom: 5px;
}

.blog .posts-list .post-date {
  font-size: 14px;
  color: #3c3c3c;
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Blog Details
--------------------------------------------------------------*/
.blog .blog-details {
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  padding: 30px;
  border-radius: 10px;
}

.blog .blog-details .post-img {
  margin: -30px -30px 20px -30px;
  overflow: hidden;
  border-radius: 10px 10px 0 0;
}

.blog .blog-details .title {
  font-size: 28px;
  font-weight: 700;
  padding: 0;
  margin: 20px 0 0 0;
  color: var(--color-default);
}

.blog .blog-details .content {
  margin-top: 20px;
}

.blog .blog-details .content h3 {
  font-size: 22px;
  margin-top: 30px;
  font-weight: bold;
}

.blog .blog-details .content blockquote {
  overflow: hidden;
  background-color: rgba(34, 34, 34, 0.06);
  padding: 60px;
  position: relative;
  text-align: center;
  margin: 20px 0;
}

.blog .blog-details .content blockquote p {
  color: var(--color-default);
  line-height: 1.6;
  margin-bottom: 0;
  font-style: italic;
  font-weight: 500;
  font-size: 22px;
}

.blog .blog-details .content blockquote:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 3px;
  background-color: var(--color-secondary);
  margin-top: 20px;
  margin-bottom: 20px;
}

.blog .blog-details .meta-top {
  margin-top: 20px;
  color: #6c757d;
}

.blog .blog-details .meta-top ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  align-items: center;
  padding: 0;
  margin: 0;
}

.blog .blog-details .meta-top ul li+li {
  padding-left: 20px;
}

.blog .blog-details .meta-top i {
  font-size: 16px;
  margin-right: 8px;
  line-height: 0;
  color: var(--color-primary);
}

.blog .blog-details .meta-top a {
  color: #6c757d;
  font-size: 14px;
  display: inline-block;
  line-height: 1;
}

.blog .blog-details .meta-bottom {
  padding-top: 10px;
  border-top: 1px solid rgba(34, 34, 34, 0.15);
}

.blog .blog-details .meta-bottom i {
  color: #555555;
  display: inline;
}

.blog .blog-details .meta-bottom a {
  color: rgba(34, 34, 34, 0.8);
  transition: 0.3s;
}

.blog .blog-details .meta-bottom a:hover {
  color: var(--color-primary);
}

.blog .blog-details .meta-bottom .cats {
  list-style: none;
  display: inline;
  padding: 0 20px 0 0;
  font-size: 14px;
}

.blog .blog-details .meta-bottom .cats li {
  display: inline-block;
}

.blog .blog-details .meta-bottom .tags {
  list-style: none;
  display: inline;
  padding: 0;
  font-size: 14px;
}

.blog .blog-details .meta-bottom .tags li {
  display: inline-block;
}

.blog .blog-details .meta-bottom .tags li+li::before {
  padding-right: 6px;
  color: var(--color-default);
  content: ",";
}

.blog .blog-details .meta-bottom .share {
  font-size: 16px;
}

.blog .blog-details .meta-bottom .share i {
  padding-left: 5px;
}

.blog .post-author {
  padding: 20px;
  margin-top: 30px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.blog .post-author img {
  max-width: 120px;
  margin-right: 20px;
}

.blog .post-author h4 {
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 0px;
  padding: 0;
  color: var(--color-default);
}

.blog .post-author .social-links {
  margin: 0 10px 10px 0;
}

.blog .post-author .social-links a {
  color: rgba(34, 34, 34, 0.5);
  margin-right: 5px;
}

.blog .post-author p {
  font-style: italic;
  color: rgba(108, 117, 125, 0.8);
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Blog Sidebar
--------------------------------------------------------------*/
.blog .sidebar {
  padding: 30px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.blog .sidebar .sidebar-title {
  font-size: 20px;
  font-weight: 700;
  padding: 0;
  margin: 0;
  color: var(--color-default);
}

.blog .sidebar .sidebar-item+.sidebar-item {
  margin-top: 40px;
}

.blog .sidebar .search-form form {
  background: #fff;
  border: 1px solid rgba(34, 34, 34, 0.3);
  padding: 5px 10px;
  position: relative;
  border-radius: 50px;
}

.blog .sidebar .search-form form input[type=text] {
  border: 0;
  padding: 4px;
  border-radius: 50px;
  width: calc(100% - 60px);
}

.blog .sidebar .search-form form input[type=text]:focus {
  outline: none;
}

.blog .sidebar .search-form form button {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border: 0;
  background: none;
  font-size: 16px;
  padding: 0 25px;
  margin: -1px;
  background: var(--color-primary);
  color: #fff;
  transition: 0.3s;
  border-radius: 50px;
  line-height: 0;
}

.blog .sidebar .search-form form button i {
  line-height: 0;
}

.blog .sidebar .search-form form button:hover {
  background: rgba(0, 131, 116, 0.8);
}

.blog .sidebar .categories ul {
  list-style: none;
  padding: 0;
}

.blog .sidebar .categories ul li+li {
  padding-top: 10px;
}

.blog .sidebar .categories ul a {
  color: var(--color-default);
  transition: 0.3s;
}

.blog .sidebar .categories ul a:hover {
  color: var(--color-primary);
}

.blog .sidebar .categories ul a span {
  padding-left: 5px;
  color: rgba(34, 34, 34, 0.4);
  font-size: 14px;
}

.blog .sidebar .recent-posts .post-item+.post-item {
  margin-top: 15px;
}

.blog .sidebar .recent-posts img {
  width: 80px;
  float: left;
}

.blog .sidebar .recent-posts h4 {
  font-size: 15px;
  margin-left: 95px;
  font-weight: bold;
}

.blog .sidebar .recent-posts h4 a {
  color: var(--color-default);
  transition: 0.3s;
}

.blog .sidebar .recent-posts h4 a:hover {
  color: var(--color-primary);
}

.blog .sidebar .recent-posts time {
  display: block;
  margin-left: 95px;
  font-style: italic;
  font-size: 14px;
  color: rgba(34, 34, 34, 0.4);
}

.blog .sidebar .tags {
  margin-bottom: -10px;
}

.blog .sidebar .tags ul {
  list-style: none;
  padding: 0;
}

.blog .sidebar .tags ul li {
  display: inline-block;
}

.blog .sidebar .tags ul a {
  color: #555555;
  font-size: 14px;
  padding: 6px 20px;
  margin: 0 6px 8px 0;
  border: 1px solid #d5d5d5;
  display: inline-block;
  transition: 0.3s;
  border-radius: 50px;
}

.blog .sidebar .tags ul a:hover {
  color: #fff;
  border: 1px solid var(--color-primary);
  background: var(--color-primary);
}

.blog .sidebar .tags ul a span {
  padding-left: 5px;
  color: rgba(85, 85, 85, 0.8);
  font-size: 14px;
}

/*--------------------------------------------------------------
# Blog Comments
--------------------------------------------------------------*/
.blog .comments {
  margin-top: 30px;
}

.blog .comments .comments-count {
  font-weight: bold;
}

.blog .comments .comment {
  margin-top: 30px;
  position: relative;
}

.blog .comments .comment .comment-img {
  margin-right: 14px;
}

.blog .comments .comment .comment-img img {
  width: 60px;
}

.blog .comments .comment h5 {
  font-size: 16px;
  margin-bottom: 2px;
}

.blog .comments .comment h5 a {
  font-weight: bold;
  color: var(--color-default);
  transition: 0.3s;
}

.blog .comments .comment h5 a:hover {
  color: var(--color-primary);
}

.blog .comments .comment h5 .reply {
  padding-left: 10px;
  color: var(--color-primary);
}

.blog .comments .comment h5 .reply i {
  font-size: 20px;
}

.blog .comments .comment time {
  display: block;
  font-size: 14px;
  color: rgba(34, 34, 34, 0.8);
  margin-bottom: 5px;
}

.blog .comments .comment.comment-reply {
  padding-left: 40px;
}

.blog .comments .reply-form {
  margin-top: 30px;
  padding: 30px;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.blog .comments .reply-form h4 {
  font-weight: bold;
  font-size: 22px;
}

.blog .comments .reply-form p {
  font-size: 14px;
}

.blog .comments .reply-form input {
  border-radius: 4px;
  padding: 10px 10px;
  font-size: 14px;
}

.blog .comments .reply-form input:focus {
  box-shadow: none;
  border-color: rgba(0, 131, 116, 0.8);
}

.blog .comments .reply-form textarea {
  border-radius: 4px;
  padding: 10px 10px;
  font-size: 14px;
}

.blog .comments .reply-form textarea:focus {
  box-shadow: none;
  border-color: rgba(0, 131, 116, 0.8);
}

.blog .comments .reply-form .form-group {
  margin-bottom: 25px;
}

.blog .comments .reply-form .btn-primary {
  border-radius: 50px;
  padding: 14px 40px;
  border: 0;
  background-color: var(--color-secondary);
}

.blog .comments .reply-form .btn-primary:hover {
  background-color: rgba(248, 90, 64, 0.8);
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
.footer {
  font-size: 16px;
  /* background-color: var(--color-primary); */
  /* background-color: #a31d6ddb; */
  padding: 30px 0;
  color: white;
}

.footer .footer-info .logo {
  line-height: 0;
  margin-bottom: 25px;
}

.footer .footer-info .logo img {
  max-height: 40px;
  margin-right: 6px;
}

.footer .footer-info .logo span {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 1px;
  color: #fff;
  font-family: var(--font-primary);
}

.footer .footer-info p {
  font-size: 16px;
  font-weight:bold;
  margin-right:80px;
  margin-top:-25px;

  font-family: Nunito, sans-serif;
}

.footer .social-links a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.2);
  font-size: 16px;
  color: rgba(255, 255, 255, 0.7);
  margin-right: 10px;
  transition: 0.3s;
}

.footer .social-links a:hover {
  color: #fff;
  border-color: #fff;
}

.footer h4 {
  font-size: 16px;
  font-weight: bold;
  position: relative;
  color:white;
  font-family: Nunito, sans-serif;;
}

.footer .footer-links {
  margin-bottom: 30px;
}

.footer .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer .footer-links ul i {
  padding-right: 2px;
  color: rgba(0, 131, 116, 0.8);
  font-size: 12px;
  line-height: 0;
}

.footer .footer-links ul li a {
  padding: 6px 0;
  /* display: flex; */
  align-items: center;
  font-family:Nunito, sans-serif;
  color: #6f6f6f;
}

.footer .footer-links ul li:first-child {
  padding-top: 0;
}

.footer .footer-links ul a {
  color: rgba(255, 255, 255, 0.7);
  transition: 0.3s;
  display: inline-block;
  line-height: 1;
  /* font-weight:bold; */
  /* font-weight:600; */
}

.footer .footer-links ul a:hover {
  color: #fff;
  background-image: linear-gradient(#9f005d, #D94B93);
}

.footer .footer-contact p {
  line-height: 26px;
}

.footer .copyright {
  text-align: center;
  font-family: Nunito, sans-serif;;
}

.footer .credits {
  padding-top: 4px;
  text-align: center;
  font-size: 13px;
}

.footer .credits a {
  color: #fff;
}

